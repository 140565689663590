export default {
  DEFAULT_BLACK: '#0E122B',
  DEFAULT_GREEN: '#0A8791',
  DEFAULT_YELLOW: '#FBA83C',
  DEFAULT_GREY: '#C2C2CB',
  DEFAULT_WHITE: '#FFFFFF',
  DEFAULT_RED: '#F53920',
  SECONDARY_RED: '#FF6F59',
  SECONDARY_WHITE: '#F8F8F8',
  SECONDARY_GREEN: '#24C869',
  SECONDARY_BLACK: '#191d35',
  LIGHT_GREEN: '#CEE8E7',
  LIGHT_GREY: '#F8F7F7',
  LIGHT_GREY2: '#EAEAEA',
  LIGHT_YELLOW: '#FCE6CD',
  LIGHT_RED: '#FFC8BF',
  FABEBOOK_BLUE: '#4A61A8',
  GOOGLE_BLUE: '#53A0F4',
  INACTIVE_GREY: '#A3A3A3',
  DARK_ONE: '#121212',
  DARK_TWO: '#181818',
  DARK_THREE: '#404040',
  DARK_FOUR: '#282828',
  DARK_FIVE: '#B3B3B3',
  // MAIN_COLOR:'#2C206D',
  SALWAR_COLOR:'#009381',
  SALWAR_LIGHT_COLOR:'#e5fffe',
  BLOUSE_COLOR:'#6F69AC',
  BLOUSE_LIGHT_COLOR:'#efeff6',
  SHIRT_COLOR:'#FF5F7E',
  SHIRT_LIGHT_COLOR:'#EED7CE',
  PANT_COLOR:'#865439',
  PANT_LIGHT_COLOR:'#FCDEC0',
  S_PROCESS_COLOR:'#D83A56',
  S_CONFIRM_COLOR:'#2940D3',
  S_READY_COLOR:'#B24080',
  S_DELIVER_COLOR:'#1C7947',
  HOME_MAIN_COLOR:"#e4025c",
  HOME_LIGHT_COLOR:'#f7ebf9',
  CUSTOMER_MAIN_COLOR:'#385c24',
  CUSTOMER_LIGHT_COLOR:'#f1f8ed',
  ORDER_MAIN_COLOR:'#974a1c',
  ORDER_LIGHT_COLOR:'#fbf0e9',
  REPORT_MAIN_COLOR:'#6742F0',
  REPORT_LIGHT_COLOR:'#ecf3f9',
  RATE_MAIN_COLOR:'#A93847',
  RATE_LIGHT_COLOR:'#f9ecee',
  DASHBOARD_MAIN_COLOR:"#655D8A",
  DASHBOARD_LIGHT_COLOR:"#d4d1e0",

  MAIN_COLOR:"#064420",
  LIGHT_COLOR:"#c7f5eb",
  BACKGROUND_COLOR:"#e5faf6"
  
};
